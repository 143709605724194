/* This stylesheet generated by Transfonter (https://transfonter.org) on December 7, 2017 3:38 PM */

@font-face {
  font-family: 'HDA DIN';
  src: local('HDA DIN Regular'), local('HDADINOff'), url('HDADINOff.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HDA DIN';
  src: local('HDA DIN Bold'), local('HDADINOffBld'), url('HDADINOffBld.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HDA DIN';
  src: local('HDA DIN Medium'), local('HDADINOffMed'), url('HDADINOffMed.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
